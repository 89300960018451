<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { organizationService } from '../../../../services/super-user.service';



import {
  organizationServiceMethods,
  notificationMethods,
} from "@/state/helpers";

import {
  required,
  numeric,
  email,
  alphaNum
} from "vuelidate/lib/validators";



export default {
  page: {
    title: "Organizations ",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, },
  data() {
    return {
      organizationID: 0,
      organization: {},
      // title: "Organizations",
      items: [
        {
          text: "Smart Remit",
          href: "/"
        },
        {
          text: "Organizations",
          active: true
        }
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      action: "",
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      selectedOrganization: {},
      showUserForm: false,
      orgTypes: [{ id: "superAgent", title: "Super Agent" }, { id: "basicAgent", title: "Basic Agent" }],
      fields: [
        { key: "logoLink", sortable: true, label: "#" },
        { key: "name", label: "Organization Name", sortable: true },
        { key: "organizationType", label: "Type", sortable: true },
        // { key: "address", label: "Address", sortable: true },
        // { key: "phoneNumber", sortable: true },
        // { key: "emailAddress", sortable: true },
        { key: "actions" }
      ],

      form: {
        name: "",
        address: "",
        phoneNumber: null,
        emailAddress: "",
        organizationType: { required },
        createdBy: JSON.parse(localStorage.getItem('user')).id,
      },

      adminForm: {
        firstName: null,
        lastName: null,
        email: null,
        password: "pass@123",
        phoneNumber: null,
        nationalId: null,

        //TODO: Depends on organization type
        role: "super-admin",
        roleId: -1,
        userType: "agent",

        //TODO:from organization created
        shopId: 1,

        address: "",
        createdBy: JSON.parse(localStorage.getItem('user')).id,
      },

      submitted: false,
      submitform: false,
      submit: false,
      notificationType: "",
      noticationIcon: "",

      editForm: {
        id: null,
        name: null,
        address: null,
        phoneNumber: null,
        emailAddress: null,
        isActive: null,
        createdBy: JSON.parse(localStorage.getItem('user')).id,
      },

      showform: false
    };
  },

  validations: {
    form: {
      name: { required },
      phoneNumber: { required, numeric },
      emailAddress: { required, email },
      address: { required }
    },
    adminForm: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      phoneNumber: { required, numeric },
      nationalId: { required, alphaNum },
      role: { required },
      shopId: { required },
      address: { required }
    },
  },

  created() {

  },

  computed: {
    rows() {
      return this.organization.length;
    },
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
  mounted() {
    this.totalRows = this.items.length;
    this.organizationID = this.$route.params.organizationID;
    this.getOrganization(this.organizationID)
  },

  methods: {
    ...organizationServiceMethods,
    ...notificationMethods,

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async getOrganization(organizationID) {
      try {
        await organizationService.getOrganization(organizationID).then(response => {
          // if (response.responseBody.length > 0) {
          this.organization = response.responseBody;
          // }
        });
      } catch (error) {
        console.log(error);
      }
    },
  },

};

</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">

      <div class="col-xl-12">
        <div class="card">
          <div class="card-body m-2">
            <div class="m-3 ">
              <img :src="organization.logoLink" alt="Image" style="width: auto; height: 5em;" />

            </div>
            <!-- <pre>{{ organization }}</pre> -->
            <!-- Display the entire organization object for debugging -->
            <div class="m-4">
              <h2 class="text-cente">Branches</h2>
              <hr />
              <div class="row">
                <div v-for="branch in organization.branchList" :key="branch.id" class="col-lg-3">
                  <div class="card" id="topCurve" style="background-color:#0b1344">
                    <div class="card-body">
                      <div class="media">
                        <div class="media-body overflow-hidden">
                          <!-- Display branch-specific details -->
                          <h4 class="mb-0 text-white">{{ branch.name }}</h4>
                          <p class="text-white font-size-14 mb-2 mt-2">Manage branch, users and funds</p>
                        </div>
                        <div class="text-white">
                          <i class="ri-exchange-dollar-line font-size-28"></i>
                        </div>
                      </div>
                      <div class="media mt-2">
                        <b-button variant="light" class="media-body font-size-17"
                          @click="displayForms('withdrawal', branch)">
                          Manage
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="card generic-font-color" id="topCurve">
                    <div class="card-body">
                      <div class="media">
                        <div class="media-body overflow-hidden">
                          <h4 class="mb-0 generic-font-color">Create new Branch </h4>
                          <p class="font-size-14 mb-2 mt-2">Manage branch, users and funds</p>
                        </div>
                        <div class="">
                          <i class="ri-file-paper-2-line font-size-28"></i>
                        </div>
                      </div>
                      <div class="media mt-2 ">
                        <b-button variant="light" class=" media-body font-size-17"
                          @click="displayForms('transactionReversal')">
                          Add New
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="m-4">
              <h2 class="text-centr"> Sub Agents</h2>
              <hr />
              <div class="row">
                <div v-for="subAgent in organization.subAgentList" :key="subAgent.id" class="col-lg-2">
                  <div class="card" id="topCurve" style="background-color:#e67e22">
                    <div class="card-body">
                      <div class="media">
                        <div class="media-body overflow-hidden">
                          <!-- Display subAgent-specific details -->
                          <h4 class="mb-0 text-white">{{ subAgent.name }}</h4>
                          <p class="text-white font-size-14 mb-2 mt-2">{{ subAgent.location }}</p>
                        </div>
                        <div class="text-white">
                          <i class="ri-exchange-dollar-line font-size-28"></i>
                        </div>
                      </div>
                      <div class="media mt-2">
                        <b-button variant="light" class="media-body font-size-17"
                          @click="displayForms('withdrawal', subAgent)">
                          View
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="card generic-font-color" id="topCurve">
                    <div class="card-body">
                      <div class="media">
                        <div class="media-body overflow-hidden">
                          <h4 class="mb-0 generic-font-color"> </h4>
                          <p class="font-size-14 mb-2 mt-2">Manage branch, users and funds</p>
                        </div>
                        <div class="">
                          <i class="ri-file-paper-2-line font-size-28"></i>
                        </div>
                      </div>
                      <div class="media mt-2 ">
                        <b-button variant="light" class=" media-body font-size-17"
                          @click="displayForms('transactionReversal')">
                          Add New
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>